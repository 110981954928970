import React, { useState } from 'react'
import './Home.css'
import logo from '../asset/logo.png';
import Rectangle1 from '../asset/Rectangle-1.png'
import Rectangle2 from '../asset/Rectangle-2.png'
import Rectangle3 from '../asset/Rectangle-3.png'
import Ellipse1 from '../asset/Ellipse 1.png'
import Ellipse2 from '../asset/Ellipse 2.png'
import EllipseImg from '../asset/Ecllipse-img.png'
import travelen from '../asset/trvaelen.png'
import travelen1 from '../asset/travelen1.png'
import travelen2 from '../asset/travelen2.png'
import travelen3 from '../asset/travelen3.png'
import travelen4 from '../asset/travelen4.png'
import travelenMain from '../asset/travelen-main.png'
import service1 from '../asset/service1.png'
import service2 from '../asset/service2.png'
import service3 from '../asset/service3.png'
import service4 from '../asset/service4.png'
import service5 from '../asset/service5.png'

import pic1 from '../asset/pic1.png'
import pic2 from '../asset/pic2.png'
import pic3 from '../asset/pic3.png'
import directorImg from '../asset/Director-img.png'
import balachandran1 from '../asset/balachandran1.png'
import balachandran2 from '../asset/balachandran2.png'
import balachandran3 from '../asset/balachandran3.png'

import wildbird1 from '../asset/wildbird1.png'
import wildbird2 from '../asset/wildbird2.png'
import wildbird3 from '../asset/wildbird3.png'

import ashmi1 from '../asset/ashmi1.png'
import ashmi2 from '../asset/ashmi2.png'
import ashmi3 from '../asset/ashmi3.png'

import ponni1 from '../asset/ponni1.png'
import ponni2 from '../asset/ponni2.png'
import ponni3 from '../asset/ponni3.png'













function Home() {

  const [currentRow, setCurrentRow] = useState(0); // State to keep track of the current review row

  const handleScrollRight = () => {
    setCurrentRow(prevRow => Math.min(prevRow + 1, 4)); // Increment the current row when scrolling right, but do not exceed 2
  };

  const handleScrollLeft = () => {
    setCurrentRow(prevRow => Math.max(prevRow - 1, 0)); // Decrement the current row when scrolling left, but do not go below 0
  };

  
  return (
    <>
      <nav id="nav" class="navbar navbar-expand-lg navbar-dark border fixed-top bg-white">
        <img class="logo"
          src={logo}
          alt="image" />
        <div class="container">
          {/* <!-- <a class="navbar-brand" href="#">Navbar</a> --> */}
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li class="listitem">
                <a class="link" aria-current="page" href="#home">Home</a>
              </li>
              <li class="listitem">
                <a class="link" aria-current="page" href="#aboutus">About Us</a>
              </li>
              <li class="listitem">
                <a class="link" aria-current="page" href="#travelen">Travelen</a>
              </li>
              <li class="listitem">
                <a class="link" aria-current="page" href="#services">Services</a>
              </li>
              <li class="listitem">
                <a class="link" aria-current="page" href="#reviews">Reviews</a>
              </li>
              <li class="listitem">
                <a class="link" aria-current="page" href="#contactus">Contact Us</a>
              </li>



            </ul>
          </div>
        </div>
      </nav>


      {/* Home */}
      <section id='home'>
        <div className="container home-container">
          <div className="row">
            <div className="col-md-6 ">
              <div className='col-maindiv'>
                <h1 className='heading1 text-center'>Plan Your Trip </h1>
                <h5 className='heading2 text-center'>Explore The World With Us.</h5>
                <div className='tavel-btn-div text-center'><button className='btn travel-btn'>Travel Agency</button></div>
              </div>

            </div>
            <div className="col-md-6">
              <div className='circle'>

                <div className="circle-container">
                  <div className="image-item rotate-1">
                    <img src={Rectangle1} alt="Travel 1" />
                  </div>
                  <div className="image-item rotate-2">
                    <img src={Rectangle3} alt="Travel 2" />
                  </div>
                  <div className="image-item rotate-3">
                    <img src={Rectangle2} alt="Travel 3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </section>
      <section id='aboutus'>
        <div className="container aboutus-container">
          <div className="row">
            <div className="col-md-5 d-flex justify-content-center">
              {/* <div className="ellipse-container">

                <div className="ellipse-item ellipse-left">
                  <img src={Ellipse1} alt="Image 1" />
                </div>
                  <div className="ellipse-item ellipse-right ">
                    <img src={Ellipse2} alt="Image 2" />
                  </div>
              </div> */}

              <div className="ellipse-container">

                <div className="ellipse-item ">
                  <img src={EllipseImg} alt="Image 1" />
                </div>

              </div>
            </div>
            <div className="col-md-7 ">
              <div>
                <div className='aboutus-text'>About Us</div>
                <div className='aboutus-desc'>Prayaanah Holidays started its operations from 2015 onwards with its office in Cochin. In the initial
                  stages of inception, we were focusing on very small number of customer base with limited resources
                  to supply. We decide to be very choosy in selecting in destination, hotels, property, transportation
                  and other services. Gradually we ended up in providing domestic tourism only and that too focusing
                  on Kerala market. Post Covid era, we were forced to close our operations as it was very difficult to
                  run the show with number of restrictions and decline in tourists count.</div>
                <div className='aboutus-desc'>After 3 years we decided to re-introduce ourself in the market in a new avatar. We are now
                  PRAYAANAH HOLIDAYS PVT LTD, a registered company. We revamped ourself with new
                  initiatives and re-designed our services to other sectors also. We are now providing customized
                  and fixed packages in both domestic & international destinations. We have specialized teams for
                  international & domestic packages with all the amenities.
                </div>
              </div>
            </div>
          </div>

          <div className="row vm-row ">
            <div className="col-md-12 d-flex">
              <div className="vision-content">
                <div className="v-heading">Vision</div>
                <div className="v-description">To be the best ,most trusted & Highly recommended destination
                  planner in the industry by promoting innovations & new concepts
                  in tourism without comprosmising on customer service.</div>
              </div>
              <div className="mission-content">
                <div className="m-heading">Mission</div>
                <div className="m-description">Act as a responsible destination planner by providing best in class service, new destinations, budget oriented & innovative concepts, which in turn helps the travelers to fulfill their dream holiday.</div>

              </div>
            </div>

          </div>
        </div>
        <div className="container director-msg-container">
          <div className="row ">
            <div className="col-md-9 ">
              <div className="director-ms-content ">
                <div className='dir-msg-heading'>Director’s Message   :</div>
                <div className='dir-msg-desc1'>We, as a responsible & professional destination planner, believe in providing holidays with ease & tranquillityWe aim at providing
                  the best suitable holidays options for our customers starting from budget to destinations. With years of experience in Travel &
                  Tourism Industry, we tend to believe that we can be your perfect travel planner for your dream holiday. We have always focused
                  on providing handpicked destinations, hotels, vehicles etc. for our customers during their holidays. As a part of our Customer
                  Service plan, we havepersonally visited the hotels & resorts to ensure the quality of rooms & services provided to our guests.
                  We only suggest those hotels toour customers, so that we are rest assured about their experience during their stay there.</div>
                <div className='dir-msg-desc2'>We consider each and every customer as our assets and ensure that they are provided with best-in-class service & safety
                  during the entire holiday. That's why we personally handpick destinations & properties for all our packages. Afterall
                  "ADITHI DEVO BHAVA" is the part of our culture & we keep it as our mantra.</div>
                <div className='dir-msg-desc3'>We thank all who has given us an opportunity in the past, present & future. This won't be possible without your support
                  and we expect the same for a new beginning.</div>
              </div>

            </div>
            <div className="col-md-3">
              <div className="director-img">
                <img src={directorImg} alt="director-img" />
              </div>
            </div>

          </div>
        </div>
      </section>

      <section id='travelen'>
        <div className="container">
          <div className="travelen-img text-center">
            <img src={travelen} alt="" />
          </div>
          <div className="row travelen-row">
            <div className="col-md-12">
              <div className='trav-main-div'>
                <img src={travelenMain} alt="travelen-main" />
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className="travelen1">
              <img src={travelen1} alt="" />
            </div>
            </div>
            <div className="col-md-4">
            <div className="travelen2">
              <img src={travelen2} alt="" />
            </div>
            </div>
            <div className="col-md-4">
              <div className="row">          
                <img className='trav-3' src={travelen3} alt="" />
              </div>             
              <div className="row">
               <img className='trav-4' src={travelen4} alt="" />
              </div>           
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className='large-content'><span className='text-success span-travelen'>TRAVALEN</span>  is a new age travel concept introduced by Prayaanah Holidays. A lot of new things are happening in the tourism industry with regards to services, options of travel, destinations etc.
                Still travellers are not satisfied completely due to the limited options to design their own holidays. We understood that travellers are more worried about budgets when it comes down to a
                holiday to their dream destination. To solve this problem, we came up with a unique concept called TRAVALEN. This is one-of-a-kind travel concept where anybody can design their own
                holidays based on their budgets, let it be accommodation, meals, days, destinations etc. Multiple levels of options for every customer to choose with different categories of Hotels, Destinations,
                Number of travellers etc. By introducing this idea, we tend to create a new travel concept for customers across the globe to enjoy their dream holiday without worrying about budget!!!
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='services'>
        <div className="container service-container">
          <div className="space"></div>
          <div className='d-flex'>
            <div className="arrow-line"></div>
            <div className='mt-1 services-text'>Services</div>
          </div>

          <div className="row service-row">
            <div className="col-md-4">
              <div className="service service1 flex-column">
                <img src={service1} alt="" />
                <div className='service-img-desc'> CORPORATE PACKAGES</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service service2 flex-column">
                <img src={service2} alt="" />
                <div className='service-img-desc'>MICE</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service service3 flex-column">
                <img src={service3} alt="" />
                <div className='service-img-desc'>FIXED DOMESTIC & INTERNATIONAL <br />
                  PACKAGES</div>
              </div>
            </div>
          </div>
          <div className="row service-row ">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <div className="service service4 flex-column">
                <img src={service4} alt="" />
                <div className='service-img-desc'>CUSTOMIZED DOMESTIC &
                  INTERNATIONAL PACKAGES</div>
              </div>
            </div>
            <div className="col-md-2"></div>

            <div className="col-md-3">
              <div className="service service5 flex-column">
                <img src={service5} alt="" />
                <div className='service-img-desc'>   COMPLETE HOLIDAY PLANNING
                  (ACCOMMODATION, MEALS, SIGHTSEEING,
                  TRANSPORTATION ETC.)</div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="space"></div>
        </div>
      </section>

      <section id='reviews'>
        
      <button className="scroll-arrow scroll-arrow-left" style={{
      position: 'absolute',
      left: '60px', // Adjust as necessary
      // top: '50%',
      transform: 'translateY(292%)',
      zIndex: 10,
    }} onClick={handleScrollLeft}><i className="fa-solid fa-circle-arrow-left"></i></button>

        <div className="container review-container  ">

          {/* Display the current review row */}
          <div className="scrollable-review-row" style={{ display: 'flex', minHeight: '100%' }}>
            <div className="row review-row " style={{ display: currentRow === 0 ? 'flex' : 'none', flex: 1 }}>

              {/* First review row content */}
              <div className="col-md-1">
                <div className='email-div'>M</div>
              </div>
              <div className="col-md-6">
                <div className="review-content">
                  <div className="reviewer-name">Manju Sreejith</div>
                  {/* Other review details */}
                  {/* <div className='d-flex'>
                    <div className='review-count'>2 Reviews</div>
                    <div className='photo-count'> 2 Photos</div>
                  </div> */}
                  <div className="rating-container d-flex">
                    {/* Rating stars and month count */}
                    <div className="star-rating">
                      <input type="radio" id="5-stars-1" name="rating" value="5" />
                      <label htmlFor="5-stars-1">&#9733;</label>
                      <input type="radio" id="4-stars-1" name="rating" value="4" />
                      <label htmlFor="4-stars-1">&#9733;</label>
                      <input type="radio" id="3-stars-1" name="rating" value="3" />
                      <label htmlFor="3-stars-1">&#9733;</label>
                      <input type="radio" id="2-stars-1" name="rating" value="2" />
                      <label htmlFor="2-stars-1">&#9733;</label>
                      <input type="radio" id="1-star-1" name="rating" value="1" />
                      <label htmlFor="1-star-1">&#9733;</label>
                    </div>
                    {/* <div className="month-count">a month ago</div> */}
                  </div>

                  {/* Review description */}
                  <div className="review-desc">
                    It was indeed a wonderful trip that I will cherish for a lifetime. It was organised in the best possible way which ensured we go to all the locations as per plan... Thanks for ensuring this once in a lifetime trip becomes memorable..
                  </div>
                  {/* Review photos */}
                  <div className='review-photos d-flex'>
                    <img src={pic1} alt="Photo 1" />
                    <img src={pic2} alt="Photo 2" />
                    <img src={pic3} alt="Photo 3" />
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
          </div>

          {/* Second review row */}
          <div className="scrollable-review-row" style={{ display: 'flex', minHeight: '100%' }}>
            <div className="row review-row " style={{ display: currentRow === 1 ? 'flex' : 'none', minHeight: '100%' }}>

              {/* Second review row content */}
              {/* Similar structure as the first review row */}
              {/* First review row content */}
              <div className="col-md-1">
                <div className='email-div'>B</div>
              </div>
              <div className="col-md-6">
                <div className="review-content">
                  <div className="reviewer-name">Balachandran B</div>
                  {/* Other review details */}
                  {/* <div className='d-flex'>
                    <div className='review-count'>2 Reviews</div>
                    <div className='photo-count'> 2 Photos</div>
                  </div> */}
                  <div className="rating-container d-flex">
                    {/* Rating stars and month count */}
                    <div className="star-rating">
                      <input type="radio" id="5-stars-1" name="rating" value="5" />
                      <label htmlFor="5-stars-1">&#9733;</label>
                      <input type="radio" id="4-stars-1" name="rating" value="4" />
                      <label htmlFor="4-stars-1">&#9733;</label>
                      <input type="radio" id="3-stars-1" name="rating" value="3" />
                      <label htmlFor="3-stars-1">&#9733;</label>
                      <input type="radio" id="2-stars-1" name="rating" value="2" />
                      <label htmlFor="2-stars-1">&#9733;</label>
                      <input type="radio" id="1-star-1" name="rating" value="1" />
                      <label htmlFor="1-star-1">&#9733;</label>
                    </div>
                    {/* <div className="month-count">a month ago</div> */}
                  </div>

                  {/* Review description */}
                  <div className="review-desc">
                  It was a great experience and had a nice host to cater my holiday with my family Would surely recommend ❤️👍🏽
                  </div>
                  {/* Review photos */}
                  <div className='review-photos d-flex'>
                    <img src={balachandran1} alt="Photo 1" />
                    <img src={balachandran2} alt="Photo 2" />
                    <img src={balachandran3} alt="Photo 3" />
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>

            </div>
          </div>
 {/* third review row */}
 <div className="scrollable-review-row " style={{ display: 'flex', minHeight: '100%' }}>
            <div className="row review-row " style={{ display: currentRow === 2 ? 'flex' : 'none', minHeight: '100%' }}>

              {/* Second review row content */}
              {/* Similar structure as the first review row */}
              {/* First review row content */}
              <div className="col-md-1">
                <div className='email-div'>W</div>
              </div>
              <div className="col-md-6">
                <div className="review-content">
                  <div className="reviewer-name">Willbird Advertisers</div>
                  {/* Other review details */}
                  {/* <div className='d-flex'>
                    <div className='review-count'>2 Reviews</div>
                    <div className='photo-count'> 2 Photos</div>
                  </div> */}
                  <div className="rating-container d-flex">
                    {/* Rating stars and month count */}
                    <div className="star-rating">
                      <input type="radio" id="5-stars-1" name="rating" value="5" />
                      <label htmlFor="5-stars-1">&#9733;</label>
                      <input type="radio" id="4-stars-1" name="rating" value="4" />
                      <label htmlFor="4-stars-1">&#9733;</label>
                      <input type="radio" id="3-stars-1" name="rating" value="3" />
                      <label htmlFor="3-stars-1">&#9733;</label>
                      <input type="radio" id="2-stars-1" name="rating" value="2" />
                      <label htmlFor="2-stars-1">&#9733;</label>
                      <input type="radio" id="1-star-1" name="rating" value="1" />
                      <label htmlFor="1-star-1">&#9733;</label>
                    </div>
                    {/* <div className="month-count">a month ago</div> */}
                  </div>

                  {/* Review description */}
                  <div className="review-desc">
                  Enjoyed every moment. Thanks to Prayaanah
                  </div>
                  {/* Review photos */}
                  <div className='review-photos d-flex'>
                    <img src={wildbird1} alt="Photo 1" />
                    <img src={wildbird2} alt="Photo 2" />
                    <img src={wildbird3} alt="Photo 3" />
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>

            </div>
          </div>
          {/* fourth review row */}
 <div className="scrollable-review-row " style={{ display: 'flex', minHeight: '100%' }}>
            <div className="row review-row " style={{ display: currentRow === 3 ? 'flex' : 'none', minHeight: '100%' }}>

              {/* Second review row content */}
              {/* Similar structure as the first review row */}
              {/* First review row content */}
              <div className="col-md-1">
                <div className='email-div'>A</div>
              </div>
              <div className="col-md-6">
                <div className="review-content">
                  <div className="reviewer-name">Ashmi Surendran</div>
                  {/* Other review details */}
                  {/* <div className='d-flex'>
                    <div className='review-count'>2 Reviews</div>
                    <div className='photo-count'> 2 Photos</div>
                  </div> */}
                  <div className="rating-container d-flex">
                    {/* Rating stars and month count */}
                    <div className="star-rating">
                      <input type="radio" id="5-stars-1" name="rating" value="5" />
                      <label htmlFor="5-stars-1">&#9733;</label>
                      <input type="radio" id="4-stars-1" name="rating" value="4" />
                      <label htmlFor="4-stars-1">&#9733;</label>
                      <input type="radio" id="3-stars-1" name="rating" value="3" />
                      <label htmlFor="3-stars-1">&#9733;</label>
                      <input type="radio" id="2-stars-1" name="rating" value="2" />
                      <label htmlFor="2-stars-1">&#9733;</label>
                      <input type="radio" id="1-star-1" name="rating" value="1" />
                      <label htmlFor="1-star-1">&#9733;</label>
                    </div>
                    {/* <div className="month-count">a month ago</div> */}
                  </div>

                  {/* Review description */}
                  <div className="review-desc">
                  A well planned and executed trip....within a short period u managed to give us the best ...thanks for all the assistance provided.... throughout the journey you got us connected,which made our journey more enjoyable and tension free.....                  </div>
                  {/* Review photos */}
                  <div className='review-photos d-flex'>
                    <img src={ashmi1} alt="Photo 1" />
                    <img src={ashmi2} alt="Photo 2" />
                    <img src={ashmi3} alt="Photo 3" />
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>

            </div>
          </div>

          {/* fifth review row */}
 <div className="scrollable-review-row " style={{ display: 'flex', minHeight: '100%' }}>
            <div className="row review-row " style={{ display: currentRow === 4 ? 'flex' : 'none', minHeight: '100%' }}>

              {/* Second review row content */}
              {/* Similar structure as the first review row */}
              {/* First review row content */}
              <div className="col-md-1">
                <div className='email-div'>P</div>
              </div>
              <div className="col-md-6">
                <div className="review-content">
                  <div className="reviewer-name">Ponni Narayanan</div>
                  {/* Other review details */}
                  {/* <div className='d-flex'>
                    <div className='review-count'>2 Reviews</div>
                    <div className='photo-count'> 2 Photos</div>
                  </div> */}
                  <div className="rating-container d-flex">
                    {/* Rating stars and month count */}
                    <div className="star-rating">
                      <input type="radio" id="5-stars-1" name="rating" value="5" />
                      <label htmlFor="5-stars-1">&#9733;</label>
                      <input type="radio" id="4-stars-1" name="rating" value="4" />
                      <label htmlFor="4-stars-1">&#9733;</label>
                      <input type="radio" id="3-stars-1" name="rating" value="3" />
                      <label htmlFor="3-stars-1">&#9733;</label>
                      <input type="radio" id="2-stars-1" name="rating" value="2" />
                      <label htmlFor="2-stars-1">&#9733;</label>
                      <input type="radio" id="1-star-1" name="rating" value="1" />
                      <label htmlFor="1-star-1">&#9733;</label>
                    </div>
                    {/* <div className="month-count">a month ago</div> */}
                  </div>

                  {/* Review description */}
                  <div className="review-desc">
                  {/* Review photos */}
                  <div className='review-photos d-flex'>
                    <img src={ponni1} alt="Photo 1" />
                    <img src={ponni2} alt="Photo 2" />
                    <img src={ponni3} alt="Photo 3" />
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>

            </div>
          </div>

        </div>
        </div>


        <button className="scroll-arrow" style={{
      position: 'absolute',
      right: '60px', // Adjust as necessary
      // top: '50%',
      transform: 'translateY(-590%)',
      zIndex: 10,}} onClick={handleScrollRight}><i className="fa-solid fa-circle-arrow-right"></i></button>

      </section>

      <section id='contactus'>
        <div className='d-flex mt-4'>
          <div className="arrow-line2"></div>
          <div className='mt-1 services-text'>Contact Us</div>
        </div>


        <div className="container">

          <div className="row">
            <div className="col-md-6">
              <div className="footer-content1">
                <div className="logo-footer">
                  <img src={logo} alt="" />

                </div>
                <div className='footer-text1'>Book your trip in minute, get full <br />
                  Control for much longer.
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-content2">
                <div>
                  <div className='d-flex'>
                    <div className='footer-icons'><i class="fa-solid fa-location-dot"></i></div>
                    <div className='footer-con'>Room No V411, 60/49E, 3rd Floor, JC Chambers, Opp Kairali Apartments, <br />
                      Panampilly Nagar, Ernakulam, Kerala – 682036</div>
                  </div>
                  <div className='d-flex'>
                    <div className='footer-icons'><i class="fa-solid fa-phone"></i></div>
                    <div className='footer-con'>+91 77367 83866, +91 484 4612759</div>
                  </div>
                  <div className='d-flex'>
                    <div className='footer-icons'><i className="fa-regular fa-envelope"></i></div>
                    <div className='footer-con'><a href="mailto:info@prayaanah.com">info@prayaanah.com</a></div>
                  </div>
                  <div className='d-flex'>
                    <div className='footer-icons'><i className="fa-brands fa-facebook-f"></i></div>
                    <div className='footer-con'><a href="https://www.facebook.com/prayaanah" target="_blank" rel="noopener noreferrer">www.facebook.com/prayaanah</a></div>
                  </div>
                  <div className='d-flex'>
                    <div className='footer-icons'><i className="fa-brands fa-instagram"></i></div>
                    <div className='footer-con'><a href="https://www.instagram.com/prayaanah_holidays" target="_blank" rel="noopener noreferrer">www.instagram.com/prayaanah_holidays</a></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className='last-footer'>
          <div className='d-flex justify-content-between'>
            <div className='last-footer-content foot1'>2024 © Prayaanah Holidays Pvt Ltd</div>
            <div className=' last-footer-content foot2'>Designed&Developed by Qincore labs Pvt Ltd.</div>
          </div>
        </div>
      </section>








    </>
  )
}

export default Home
