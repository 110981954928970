import './App.css';
import Home from './component/Home';

function App() {
  return (
<>
<Home/>
</>
  );
}

export default App;
